var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"model-box monitor-carousel-box"},[_c('el-carousel',{ref:"model-box",staticClass:"item-box",staticStyle:{"height":"100%"},attrs:{"trigger":"click","indicator-position":"none","autoplay":false}},[_vm._l((_vm.carouselLength),function(carouselItem,carouselIndex){return _c('el-carousel-item',{key:carouselIndex},[(_vm.carouselLength)?_c('div',{staticClass:"container"},_vm._l((_vm.dataList.length -
            _vm.showLlength * carouselIndex <
          _vm.showLlength
            ? _vm.dataList.length - _vm.showLlength * carouselIndex
            : _vm.showLlength),function(item,index){return _c('div',{key:index,staticClass:"container-item",style:({ width: _vm.videoWidth + 'px', height: _vm.videoHeigth + 'px' })},[_c('div',{staticClass:"container-item-content"},[_c('div',{staticClass:"container-item-title"},[_c('span',[_vm._v(_vm._s(_vm.dataList[index + carouselIndex * _vm.showLlength].plate))]),_c('span',[_vm._v("报警次数："+_vm._s(_vm.dataList[index + carouselIndex * _vm.showLlength].alarmTimes))])]),_c('div',{staticClass:"container-item-video",style:({
                backgroundImage: false
                  ? 'url(' +
                    '../../../../assets/images/bigScreen/risk/monitor-video.jpg' +
                    ')'
                  : '',
              })},[_c('i',{staticClass:"el-icon-video-play container-item-video-icon",on:{"click":function($event){return _vm.handleGo(_vm.dataList[index + carouselIndex * _vm.showLlength])}}})]),_c('div',{staticClass:"container-item-trait"},[_c('span',[_vm._v(_vm._s(_vm.dataList[index + carouselIndex * _vm.showLlength].cameraLineName))]),_c('span',[_vm._v("工作时长："+_vm._s(_vm.dataList[index + carouselIndex * _vm.showLlength].workingTime)+"小时")])])])])}),0):_vm._e()])}),(!(_vm.carouselLength || _vm.carouselLength.length))?_c('el-carousel-item',[_c('div',{staticClass:"vacancy-box"},[_c('img',{attrs:{"src":require("../../../../assets/images/bigScreen/risk/vacancy.png"),"alt":"","srcset":""}}),_c('div',[_vm._v("暂无数据")])])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }