<template>
  <div class="model-box" v-loading="isLoading">
    <div
      class="alarm-box"
      v-for="(item, index) in dataList"
      :key="index"
      :class="{ 'alarm-box-s': index == currentIndex }"
      @click="
        currentIndex == index ? (currentIndex = null) : (currentIndex = index)
      "
    >
      <div class="alarm-box-name">{{ item.alarmTypeChn }}</div>
      <div class="alarm-box-num">{{ item.vehicleCount }}<span>台</span></div>
    </div>
    <div class="vacancy-box" v-if="!(dataList && dataList.length)">
      <img
        src="../../../../assets/images/bigScreen/risk/vacancy.png"
        alt=""
        srcset=""
      />
      <div>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { getRealTimeAlarms } from "@/api/BigScreenDisplay";
export default {
  data() {
    return {
      dataList: [],
      isLoading: false,
      currentIndex: null,
    };
  },
  watch: {
    currentIndex: {
      handler(v) {
        this.$emit(
          "changeCondition",
          this.currentIndex !== null
            ? this.dataList[this.currentIndex].alarmType
            : ""
        );
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      getRealTimeAlarms()
        .then((res) => {
          this.isLoading = false;
          this.dataList = res.obj;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  position: relative;
  padding: 7%;
  overflow: auto;
  .alarm-box {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
    padding-left: 0.16rem;
    padding-right: 0.18rem;
    background-image: url("../../../../assets/images/bigScreen/risk/alarm-box-bg2.png");
    background-size: 100% 100%;
    margin-bottom: 10px;
    &-name {
      font-size: 0.14rem;
      color: #ffffff;
    }
    &-num {
      font-family: "ZhanKuQingKeHuangYouTi";
      font-size: 0.17rem;
      color: rgba(255, 253, 55, 1);
      span {
        font-size: 0.14rem;
        color: #ffffff;
        margin-left: 4px;
      }
    }
  }
  .alarm-box-s {
    background-image: url("../../../../assets/images/bigScreen/risk/alarm-box-bg.png");
  }
}
.vacancy-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 50%;
    display: block;
    margin: auto;
  }
  div {
    font-size: 0.14rem;
    color: #ffffff;
    text-align: center;
    margin-top: 8%;
  }
}
</style>
