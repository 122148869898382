<template>
  <div class="option-box">
    <div class="option-box-header">
      <div>{{ keyLabel }}</div>
      <div>{{ keyValue }}</div>
    </div>
    <div class="option-box-content" v-infinite-scroll="load">
      <div
        class="option-box-item"
        :class="{ 'option-box-item-select': (multiple ? value.includes(item.radioKey) : value == item.radioKey)}"
        v-for="(item, index) in radioList"
        :key="index"
      >
        <div class="option-box-item-text">{{ item[showLabel] }}</div>
        <div>
          <ItemRadio
            :label="item.radioKey"
            v-model="item.radioValue"
            size="0.14rem"
            :name="item[showName] + showNameUnit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemRadio from "@/views/BigScreenDisplay/components/risk/ItemRadio";
export default {
  components: { ItemRadio },
  props: {
    value: {
      type: [String, Array, Number],
      default: "",
    },
    keyLabel: {
      type: String,
      default: "",
    },
    keyValue: {
      type: String,
      default: "",
    },
    showName: {
      type: String,
      default: "radioKey",
    },
    showNameUnit: {
      type: String,
      default: "台",
    },
    showLabel: {
      type: String,
      default: "radioKey",
    },
    list: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    radioKey: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      typeAllRadio: "",
      radioList: [],
    };
  },
  watch: {
    list: {
      handler(v) {
        v && v.length ? this.radioList = JSON.parse(JSON.stringify(v)).map(val=>{ return {...val, radioValue: (this.value instanceof Array ? this.value.includes(val[this.radioKey]) ? val[this.radioKey] : '' : this.value == val[this.radioKey] ? val[this.radioKey]: ''), radioKey: val[this.radioKey]}}) : this.radioList = [];
      },
      deep: true,
      immediate: true
    },
    radioList: {
      handler(v) {
        let valueData = v.filter((val) => val.radioValue);
        if (valueData.length == 0) {
          this.$emit("input", this.multiple ? [] : "");
        } else if (this.multiple) {
          this.$emit(
            "input",
            valueData.map((val) => val.radioValue)
          );
        } else if (this.value && typeof this.value !== "object") {
          this.$emit(
            "input",
            valueData.filter((val) => this.value != val.radioValue)[0]
              .radioValue
          );
        } else {
          this.$emit("input", valueData[0].radioValue);
        }
      },
      deep: true,
    },
  },
  methods: {
    setAll(flag) {
      if (!flag) {
        this.radioList = this.radioList.map((val) => {
          return { ...val, radioValue: "" };
        });
      } else if(flag) {
        this.radioList = this.radioList.map((val) => {
          return { ...val, radioValue: val.radioKey };
        });
      }
    },
    load () {
      console.log(23232);
      // this.count += 2
    }
  },
};
</script>

<style lang="scss" scoped>
.option-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.16rem;
    color: #ffffff;
    margin-bottom: 0.14rem;
    > div {
      width: 30%;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: center;
    }
  }
  &-content {
    flex: 1;
    overflow: auto;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.14rem;
    font-weight: 300;
    color: #ffffff;
    // height: 0.46rem;
    margin-bottom: 0.32rem;
    background-image: url("../../../../assets/images/bigScreen/risk/no-select-bg.png");
    background-size: 100% 100%;
    padding: 4% 6%;
    > div {
      // width: 30%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
    &-text {
      width: calc(100% - 0.64rem);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-item-select {
    // height: 0.52rem;
    background-image: url("../../../../assets/images/bigScreen/risk/select-bg.png");
  }
}
</style>