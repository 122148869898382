<template>
  <div class="model-box" ref="model-box" v-loading="isLoading">
    <div style="height: 100%" v-if="!screeningIndex">
      <div class="statistical">
        <div class="statistical-text">
          <div>
            <div class="statistical-text-top">
              本月告警次数
              <span class="statistical-text-top-num">{{
                statisticalData.monthAlarmCount
              }}</span>
            </div>
            <div class="statistical-text-dowm">
              比上月
              <span class="statistical-text-dowm-x">{{
                statisticalData.monthAlarmUpCount
              }}</span
              ><img
                src="../../../../assets/images/bigScreen/risk/statistical-lower.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div>
            <div class="statistical-text-top">
              本月干预次数
              <span class="statistical-text-top-num">{{
                statisticalData.monthAlarmInterventionCount
              }}</span>
            </div>
            <div class="statistical-text-dowm">
              比上月
              <span class="statistical-text-dowm-s">{{
                statisticalData.monthAlarmInterventionUpCount
              }}</span
              ><img
                src="../../../../assets/images/bigScreen/risk/statistical-up.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div class="statistical-chart">
          <Chart
            style="height: 100%; min-height: 100px"
            ref="chartBox"
            :option="lineOptions"
          ></Chart>
        </div>
      </div>
      <div class="screening">
        <header-name class="tendency-title" title="实时监控"
          ><span style="font-size: 0.12rem; color: #ffffff; margin-left: 12px"
            >（筛选监控视频）</span
          ></header-name
        >
        <div class="screening-content" ref="screening-content">
          <!-- <el-popover
            placement="right"
            width="475"
            trigger="click"
            popper-class="screening-content-popover"
          >
            <div class="screening-content-popover-item">
              <div class="screening-content-popover-item-header">
                <span>选择时长</span>
                <ItemRadio
                  label="all"
                  v-model="typeAllRadio"
                  size="0.14rem"
                  name="全选"
                />
              </div>
              <div class="screening-content-popover-item-center">
                <popoverOption
                  ref="timePopover"
                  v-model="timePopoverValue"
                  key-label="时间段"
                  key-value="设备数量"
                  :list="[]"
                />
              </div>
              <div class="screening-content-popover-item-button">
                <div class="screening-content-popover-item-button-item">
                  确定
                </div>
                <div class="screening-content-popover-item-button-item">
                  取消
                </div>
              </div>
            </div> -->
          <div
            class="screening-content-item"
            :class="{
              'screening-content-current':
                groupPopoverValue && groupPopoverValue.length,
            }"
            @click="screeningIndex = 1"
            :style="{ height: itemHeight + 'px', width: itemWidth + 'px' }"
          >
            <img
              src="../../../../assets/images/bigScreen/risk/screening-car.png"
              alt=""
              srcset=""
            />
            <div>项目车组</div>
          </div>
          <el-popover placement="right" width="400" trigger="click">
            <div>2222</div>
            <div
              class="screening-content-item"
              :class="{
                'screening-content-current':
                  equipmentPopoverValue && equipmentPopoverValue.length,
              }"
              @click="screeningIndex = 2"
              :style="{ height: itemHeight + 'px', width: itemWidth + 'px' }"
              slot="reference"
            >
              <img
                src="../../../../assets/images/bigScreen/risk/screening-type.png"
                alt=""
                srcset=""
              />
              <div>机械类型</div>
            </div>
          </el-popover>
          <el-popover width="400" trigger="click">
            <div>2222</div>
            <div
              class="screening-content-item"
              :style="{ height: itemHeight + 'px', width: itemWidth + 'px' }"
              :class="{ 'screening-content-current': timePopoverValue }"
              @click="screeningIndex = 3"
              slot="reference"
            >
              <img
                src="../../../../assets/images/bigScreen/risk/screening-time.png"
                alt=""
                srcset=""
              />
              <div>工作时长</div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div
      class="screening-content-popover-item"
      v-else
      v-loading="isScreeningLoading"
    >
      <div class="screening-content-popover-item-header">
        <span
          >选择{{
            screeningIndex == 1 ? "车组" : screeningIndex == 2 ? "类型" : "时长"
          }}</span
        >
        <ItemRadio
          label="all"
          v-model="typeAllRadio"
          v-if="screeningIndex != 3"
          size="0.14rem"
          name="全选"
        />
      </div>
      <div class="screening-content-popover-item-center">
        <popoverOption
          ref="timePopover"
          v-model="screeningPopoverValue"
          :key-label="keyLabel"
          radio-key="label"
          :key-value="keyValue"
          showName="value"
          showLabel="LabelName"
          :multiple="screeningIndex != 3"
          :list="screeningList"
        />
      </div>
      <div class="screening-content-popover-item-button">
        <div
          class="screening-content-popover-item-button-item"
          @click="handleChangeMonitor"
        >
          确定
        </div>
        <div
          class="screening-content-popover-item-button-item"
          @click="screeningIndex = null"
        >
          取消
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
import ItemRadio from "@/views/BigScreenDisplay/components/risk/ItemRadio";
import popoverOption from "@/views/BigScreenDisplay/components/risk/popoverOption";
import {
  getAlarmStatistics,
  getGroupInfo,
  getMachineryEquipmentType,
  getWorkingTimeType,
} from "@/api/BigScreenDisplay";

export default {
  components: { Chart, HeaderName, ItemRadio, popoverOption },
  data() {
    return {
      lineOptions: {},
      itemHeight: 0,
      itemWidth: 0,
      screeningIndex: null,
      typeAllRadio: false,
      timePopoverValue: "",
      screeningPopoverValue: "",
      groupPopoverValue: [],
      equipmentPopoverValue: [],
      keyLabel: "",
      keyValue: "",
      showLabel: "LabelName",
      screeningList: [],
      isLoading: false,
      equipmentTypeList: [],

      groupList: [],
      timeList: [],
      isScreeningLoading: false,
      statisticalData: {},
    };
  },
  created() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  watch: {
    typeAllRadio: {
      handler(v) {
        this.$refs["timePopover"].setAll(v);
      },
    },
    screeningIndex: {
      handler(v) {
        if (!v) {
          this.$nextTick(() => {
            this.init();
          });
        } else if (v == 1) {
          this.keyLabel = "项目车组名称";
          this.keyValue = "设备数量";
          this.getGroupList();
        } else if (v == 2) {
          this.keyLabel = "设备类型";
          this.keyValue = "设备数量";
          this.getEquipmentTypeList();
        } else if (v == 3) {
          this.keyLabel = "时间段";
          this.keyValue = "设备数量";
          this.getTimeList();
        }
      },
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      getAlarmStatistics({
         accountName: this.$store.state.userInfo.name
      })
        .then((res) => {
          this.isLoading = false;
          this.statisticalData = res.obj;
          this.initCharth(res.obj);
        })
        .catch((err) => {
          this.isLoading = false;
        });
      this.$nextTick(() => {
        this.itemHeight = this.$refs["screening-content"].offsetHeight * 0.85;
        this.itemWidth = this.$refs["screening-content"].offsetWidth * 0.3;
      });
    },
    initCharth(dataList) {
      const sizeHNum = this.getSize(0.3, 2);
      const series = [
        {
          name: "告警次数",
          data: dataList.monthAlarmList.map((val) => val.value),
          type: "line",
          smooth: true,
          symbol: "none",
          color: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: "linear",
            global: false,
            colorStops: [
              {
                offset: 0,
                color: "#FFFD37",
              },
            ],
          },
        },
        {
          name: "干预次数",
          data: dataList.monthAlarmInterventionList.map((val) => val.value),
          type: "line",
          smooth: true,
          symbol: "none",
          color: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            type: "linear",
            global: false,
            colorStops: [
              {
                offset: 0,
                color: "rgba(150, 245, 248, 1)",
              },
            ],
          },
        },
      ];
      // 数图配置项
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "22%", // 等价于 y: '16%'
          left: "3%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          itemGap: sizeHNum * 1,
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: sizeHNum * 0.8,
          },
          itemWidth: sizeHNum * 0.8,
          right: 0,
          top: "6%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisTick: {
            //刻度线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: sizeHNum * 0.8,
            },
          },
        },

        yAxis: {
          name: "次数",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#ffffff",
              fontSize: sizeHNum * 0.8,
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value;
            },
            textStyle: {
              color: "#fff",
              fontSize: sizeHNum * 0.8,
            },
          },
        },
        series: series,
      };
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    handleChangeMonitor() {
      if (this.screeningIndex == 1) {
        this.groupPopoverValue = this.screeningPopoverValue;
      } else if (this.screeningIndex == 2) {
        this.equipmentPopoverValue = this.screeningPopoverValue;
      } else if (this.screeningIndex == 3) {
        this.timePopoverValue = this.screeningPopoverValue;
      }
      this.screeningList = [];
      this.screeningIndex = null;
      this.$emit("changeMonitor", {
        timeType: this.timePopoverValue ? [this.timePopoverValue] : [],
        groupId: this.groupPopoverValue,
        machineryProductType: this.equipmentPopoverValue,
      });
    },
    getGroupList() {
      this.screeningPopoverValue = this.groupPopoverValue;
      if (this.groupList.length) {
        this.screeningList = this.groupList;
        return false;
      }
      this.isScreeningLoading = true;
      getGroupInfo()
        .then((res) => {
          this.isScreeningLoading = false;
          this.screeningList = res.obj;
          this.groupList = res.obj;
        })
        .catch((err) => {
          this.isScreeningLoading = false;
        });
    },
    getEquipmentTypeList() {
      this.screeningPopoverValue = this.equipmentPopoverValue;
      if (this.equipmentTypeList.length) {
        this.screeningList = this.equipmentTypeList;
        return false;
      }
      this.isScreeningLoading = true;
      getMachineryEquipmentType()
        .then((res) => {
          this.isScreeningLoading = false;
          this.screeningList = res.obj;
          this.equipmentTypeList = res.obj;
        })
        .catch((err) => {
          this.isScreeningLoading = false;
        });
    },
    getTimeList() {
      this.screeningPopoverValue = this.timePopoverValue;
      if (this.timeList.length) {
        this.screeningList = this.timeList;
        return false;
      }
      this.isScreeningLoading = true;
      getWorkingTimeType()
        .then((res) => {
          this.isScreeningLoading = false;
          this.screeningList = res.obj;
          this.timeList = res.obj;
        })
        .catch((err) => {
          this.isScreeningLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  padding: 4%;
  .statistical {
    height: 50%;
    &-text {
      display: flex;
      justify-content: space-between;
      height: 16.6%;
      > div {
        width: 47.5%;
        height: 100%;
        padding: 1%;
        display: flex;
        flex-direction: column;
        background-image: url("../../../../assets/images/bigScreen/risk/statistical-bg.png");
        background-size: 100% 100%;
      }
      &-top {
        display: flex;
        width: 100%;
        flex: 1;
        align-items: center;
        justify-content: center;
        font-size: 0.13rem;
        font-weight: 300;
        color: #ffffff;
        &-num {
          margin-left: 4px;
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.17rem;
          color: rgba(255, 253, 55, 1);
        }
      }
      &-dowm {
        text-align: center;
        font-size: 0.13rem;
        font-weight: 300;
        color: #ffffff;
        &-s {
          margin-left: 4px;
          color: #0def02;
        }
        &-x {
          margin-left: 4px;
          color: #ff4200;
        }
        img {
          height: 0.14rem;
        }
      }
    }
    &-chart {
      height: 83.4%;
    }
  }
  .screening {
    display: flex;
    flex-direction: column;
    height: 46%;
    margin-top: 4%;
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 85%;
        width: 30%;
        background-image: url("../../../../assets/images/bigScreen/risk/screening-bg2.png");
        background-size: 100% 100%;
        cursor: pointer;
        img {
          height: 20%;
          margin-top: 25%;
        }
        div {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 20%;
          font-size: 0.15rem;
          font-weight: 300;
          color: #ffffff;
        }
      }
      &-current {
        background-image: url("../../../../assets/images/bigScreen/risk/screening-bg.png") !important;
        div {
          font-weight: bold !important;
          color: #0267cb !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.screening-content-popover {
  height: 650px;
  background-color: transparent;
  border: none;
  .popper__arrow {
    border-left-color: rgba(255, 255, 255, 0.3) !important;
  }
  .popper__arrow::after {
    border-left-color: rgba(255, 255, 255, 0.3) !important;
  }
  &-item {
    display: flex;
    flex-direction: column;
    // padding: 4%;
    // padding-top: 10.5%;
    height: 100%;
    // background-image: url("../../../../assets/images/bigScreen/risk/screening-popover.png");
    // background-size: 100% 100%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.18rem;
      font-weight: bold;
      color: #96f5f8;
    }
    &-center {
      flex: 1;
      margin-top: 0.14rem;
      overflow: hidden;
    }
    &-button {
      display: flex;
      justify-items: center;
      justify-content: space-between;
      &-item {
        width: 155px;
        height: 43px;
        line-height: 43px;
        background-image: url("../../../../assets/images/bigScreen/risk/button-item.png");
        background-size: 100% 100%;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>