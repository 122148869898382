<template>
  <div class="model-box monitor-carousel-box" v-loading="isLoading">
    <el-carousel
      trigger="click"
      indicator-position="none"
      class="item-box"
      :autoplay="false"
      ref="model-box"
      style="height: 100%"
    >
      <el-carousel-item v-for="carouselItem,carouselIndex in carouselLength" :key="carouselIndex">
        <div class="container" v-if="carouselLength">
          <div
            class="container-item"
            :style="{
              width: videoWidth + 'px',
              height: videoHeigth + 'px',
              marginRight:
                listWidth[index] + 'px',
            }"
            v-for="(item, index) in (dataList.length - showLlength * carouselIndex < showLlength ? dataList.length - showLlength * carouselIndex : showLlength)"
            :key="index"
          >
            <div class="container-item-content">
              <div class="container-item-title">
                {{ dataList[index + (carouselIndex * showLlength)].plate }}
              </div>
              <div
                class="container-item-video"
                :style="{
                  backgroundImage: false
                    ? 'url(' +
                      '../../../../assets/images/bigScreen/risk/monitor-video.jpg' +
                      ')'
                    : '',
                }"
              >
                <i
                  class="el-icon-video-play container-item-video-icon"
                  @click="handleGo(dataList[index + (carouselIndex * showLlength)])"
                ></i>
              </div>
              <div class="container-item-trait">
                <span>{{ dataList[index + (carouselIndex * showLlength)].cameraLineName }}</span>
                <span>工作时长：{{ dataList[index + (carouselIndex * showLlength)].workingTime }}小时</span>
              </div>
            </div>
          </div>
          
        </div>
      </el-carousel-item>
      <el-carousel-item v-if="!(carouselLength || carouselLength.length)">
    <div class="vacancy-box">
      <img src="../../../../assets/images/bigScreen/risk/vacancy.png" alt="" srcset="">
      <div>暂无数据</div>
    </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getMonitorDevices } from "@/api/BigScreenDisplay";
export default {
  data() {
    return {
      dataList: [],
      showLlength: 0,
      videoWidth: 0,
      videoHeigth: 0,
      showMarginWidth: 0,
      carouselLength: 0,
      isLoading: false,
      listWidth: []
    };
  },
  props: {
    monitorData: {
      type: Object,
      default: ()=> {}
    },
  },
  watch: {
    monitorData: {
      handler(v) {
        this.init()
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let a = this.$refs["model-box"].$el.offsetHeight;
        let b = this.$refs["model-box"].$el.offsetWidth;
        this.videoHeigth = a / 2 - 16;
        this.videoWidth = this.videoHeigth * 1.248;
        this.showLlength = Math.floor(b / this.videoWidth) * 2;
        this.showMarginWidth = Math.floor(Math.floor((b - this.videoWidth * (this.showLlength / 2))) / (this.showLlength / 2 - 1));
        this.listWidth = [];
        for (let index = 0; index < this.showLlength; index++) {
          this.listWidth.push((index + 1) == this.showLlength || (index + 1) == parseInt(this.showLlength / 2) ? 0 : this.showMarginWidth)
        }
        this.$forceUpdate();
        this.isLoading = true;
        getMonitorDevices(this.monitorData)
          .then((res) => {
            this.isLoading = false;
            // console.log(res.obj);
            this.dataList = res.obj;
            this.carouselLength = res.obj?.length
              ? Math.ceil(res.obj.length / this.showLlength)
              : 0;
          })
          .catch((err) => {
            this.isLoading = false;
          });
      });
    },
    handleGo(item) {
      this.$router.push({
        path: `/BigScreenDisplayRiskVideo?vehicleId=${item.vehicleId}&channel=${item.cameraLine}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  padding: 3.8% 1.4% 1.8%;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-content: space-between;
    &-item {
      background-image: url("../../../../assets/images/bigScreen/risk/container-item.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // margin-bottom: 16px;
      &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1.1%;
        padding-right: 3.4%;
      }
      &-title {
        display: flex;
        align-items: center;
        height: 10%;
        font-size: 0.16rem;
        color: #ffffff;
        padding-left: 6%;
        margin-bottom: 1%;
      }
      &-video {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // height: 81%;
        flex: 1;
        position: relative;
        background-image: url("../../../../assets/images/bigScreen/risk/monitor-video.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &-icon {
          color: #ffffff;
          font-weight: bold;
          font-size: 50px;
          cursor: pointer;
        }
      }
      &-trait {
        // height: 8.6%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 0.16rem;
        color: #ffffff;
        padding-left: 6%;
        padding-right: 6%;
        padding-top: 2%;
      }
    }
  }
}
  .vacancy-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 50%;
      display: block;
      margin: auto;
    }
    div {
      font-size: 0.14rem;
      color: #FFFFFF;
      text-align: center;
      margin-top: 8%;
    }
  }
</style>
<style lang="scss">
.monitor-carousel-box {
  .el-carousel__container {
    height: 100%;
  }
}
</style>